<div class="widget-block widget-grey-bg">
  <div class="row">
    <div [ngClass]="firstColumnClass"><h4>{{title | translate}}</h4></div>
    <div [ngClass]="secondColumnClass" class="d-flex justify-content-end">
      @if(detailsLink){
        <a class="a-link d-inline-block mt-2" href="javascript:void(0)"
        (click)="requestViewDetails()">{{detailsLink | translate}}
        </a>
      }
    </div>
    <ng-content></ng-content>
  </div>
</div>

import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-details-box',
  standalone: true,
  imports: [TranslateModule, NgClass],
  templateUrl: './details-box.component.html',
  styleUrl: './details-box.component.scss',
})
export class DetailsBoxComponent implements OnInit {
  @Input() title: string = '';
  @Input() detailsLink: string;
  @Input() firstColumnWidth: number = 8; // Default width in col-md-*
  firstColumnClass: string = '';
  secondColumnClass: string = '';

  @Output() viewDetailsRequest = new EventEmitter<void>();

  ngOnInit(): void {
    this.setColumnClasses();
  }

  requestViewDetails() {
    this.viewDetailsRequest.emit();
  }

  setColumnClasses(): void {
    if (this.firstColumnWidth > 0 && this.firstColumnWidth <= 12) {
      this.firstColumnClass = `col-md-${this.firstColumnWidth}`;
      this.secondColumnClass = `col-md-${12 - this.firstColumnWidth}`;
    } else {
      this.firstColumnClass = 'col-md-8';
      this.secondColumnClass = 'col-md-4';
    }
  }
}
